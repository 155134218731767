<template>
  <div>
    <b-table
      :current-page="page"
      per-page="8"
      :fields="[
        { label: 'id', key: 'id'},
        { label: 'Tipo da Venda', key: 'sale_type_id'},
        { label: 'Valor', key: 'value'},
        { label: 'Bandeira', key: 'product_name'},
        { label: 'Data da Transação', key: 'transaction_date'},
        { label: 'N do Cartão', key: 'card_number'},
        { label: 'Parcelas', key: 'installments'},
      ]"
      :items="items"
      class="text-center"
      responsive
    >

      <template #cell(value)="data">
        {{ data.item.value | value }}
      </template>

      <template #cell(card_holder_name)="data">
        {{ data.item.payer_name }}
      </template>

      <template #cell(product_name)="data">
        <b-img
          width="50"
          class="d-block m-auto"
          :src="getBrandImage(data.item.product_name)"
        />
      </template>

      <template #cell(transaction_date)="data">
        {{ formatDate(data.item.transaction_date) | date_hour }}
      </template>

      <template #cell(card_number)="data">
        {{ data.item.card_number }}
      </template>

      <template #cell(sale_type_id)="data">
        {{ data.item.sale_type_id === 1 ? 'Débito' : 'Crédito' }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="8"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import { BTable, BImg, BPagination } from 'bootstrap-vue';
import { DateTime } from 'luxon';

export default {
    components: {
        BTable,
        BImg,
        BPagination,
    },

    props: {
        items: {
            required: true,
            type: Array,
        },
    },

    data() {
        return {
            page: 1,

        };
    },

    methods: {
        getBrandImage(card) {
            const paymentSchemesToImg = {
                Mastercard: 'mastercard.svg',
                Visa: 'visa.svg',
                Elo: 'elo.svg',
            };
            const src = paymentSchemesToImg[card] || 'default.svg';
            // eslint-disable-next-line import/no-dynamic-require, global-require
            const img = require(`@/assets/images/brands/${src}`);

            return img;
        },

        formatDate(date) {
            const dateTime = DateTime.fromISO(date);

            const dateTimeZone = dateTime.plus({ hours: 3 });
            return dateTimeZone;
        },
    },
};

</script>
