<template>
  <div>
    <Header
      @click-button="getData"
    />

    <Form
      v-if="merchant.address"
      :merchant="merchant"
    />

    <alerts
      v-if="merchant.address"
      :items="items"
    />
  </div>
</template>

<script>
import Header from './Header.vue';
import Form from './Form.vue';
import Alerts from './Alerts.vue';

import { getEc } from '@/service/transaction_monitoring/ec';
import AlertService from '@/service/transaction_monitoring/alerts';

import { callLoading } from '@/service/utilities';

export default {
    components: {
        Alerts,
        Header,
        Form,
    },

    data() {
        return {
            merchant: {},
            items: [],
        };
    },

    watch: {
        merchant(value) {
            this.getAlertByMerchantCode(value.establishment_id);
        },
    },

    methods: {
        async getData({ ec }) {
            callLoading(true);
            const { data: { data } } = await getEc(ec);
            this.merchant = data;
        },

        async getAlertByMerchantCode(ec) {
            callLoading(true);
            const { data } = await AlertService.getAlerts({
                ec,
            });

            this.items = data.map(item => ({
                ...item,
                atypical: !!item.atypical,
            }));
            callLoading(false);
        },
    },
};
</script>
