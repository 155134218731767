var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"text-center",attrs:{"current-page":_vm.page,"per-page":"8","fields":[
      { label: 'id', key: 'id'},
      { label: 'Tipo da Venda', key: 'sale_type_id'},
      { label: 'Valor', key: 'value'},
      { label: 'Bandeira', key: 'product_name'},
      { label: 'Data da Transação', key: 'transaction_date'},
      { label: 'N do Cartão', key: 'card_number'},
      { label: 'Parcelas', key: 'installments'} ],"items":_vm.items,"responsive":""},scopedSlots:_vm._u([{key:"cell(value)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("value")(data.item.value))+" ")]}},{key:"cell(card_holder_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.payer_name)+" ")]}},{key:"cell(product_name)",fn:function(data){return [_c('b-img',{staticClass:"d-block m-auto",attrs:{"width":"50","src":_vm.getBrandImage(data.item.product_name)}})]}},{key:"cell(transaction_date)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("date_hour")(_vm.formatDate(data.item.transaction_date)))+" ")]}},{key:"cell(card_number)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.card_number)+" ")]}},{key:"cell(sale_type_id)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.sale_type_id === 1 ? 'Débito' : 'Crédito')+" ")]}}])}),_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.items.length,"per-page":8,"align":"center","size":"sm"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }