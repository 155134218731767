<template>
  <b-row>
    <b-col
      class="m-auto"
      md="3"
    >
      <b-card>
        <validation-observer ref="simpleRules">

          <b-form>
            <b-form-group
              label="EC"
              label-for="establishment-id"
            >
              <validation-provider
                #default="{ errors }"
                name="Ec"
                rules="required"
              >
                <b-input-group>
                  <template #append>
                    <b-button
                      variant="primary"
                      :disabled="errors.length > 0"
                      @click="clickButton"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        size="12"
                      />
                    </b-button>
                  </template>
                  <b-form-input
                    v-model="ec"
                    placeholder="EC"
                  />
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
    BButton, BFormInput, BInputGroup, BRow, BCol, BFormGroup, BForm, BCard,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import '@validations';

export default {
    components: {
        BButton, BFormInput, BInputGroup, BRow, BCol, BFormGroup, BForm, BCard, ValidationProvider, ValidationObserver,
    },

    data() {
        return {
            ec: this.$route.params.id || '',
        };
    },

    mounted() {
        if (this.ec) this.clickButton();
    },

    methods: {
        clickButton() {
            this.$emit('click-button', { ec: this.ec });
        },
    },
};
</script>
