<template>
  <b-card>
    <b-row>
      <b-col md="2">
        <b-form-group
          label="Verificados"
          label-for="verify-filter"
        >
          <b-form-select
            id="verify-filter"
            v-model="verify"
            :readonly="readOnly"
            :options="[
              { value: null, text: 'Selecione um Filtro'},
              { value: 'not-analyzed', text: 'Não' },
              { value: 'analyzed', text: 'Analisados' }
            ]"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group
          label="Data Inicial"
          label-for="start-date"
        >
          <b-form-input
            id="start-date"
            v-model="startDate"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group
          label="Data Final"
          label-for="end-date"
        >
          <b-form-input
            id="end-date"
            v-model="endDate"
            type="date"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <!-- <Summary
      :alerts="filteredItems"
      :start-date="startDate"
      :end-date="endDate"
      :alert-type-id-to-title="alertTypeIdToTitle"
    /> -->

    <b-table
      v-if="items"
      :key="tableKey"
      :items="filteredItems"
      class="text-center alert-table"
      show-empty
      responsive="xl"
      hover
      :current-page="page"
      :per-page="8"
      empty-text="Não há alertas"
      :fields="[
        { key: 'verify', label:'Verificar'},
        { key: 'verified', label:'Verificado'},
        { key: 'id', label:'id'},
        { key: 'transactions', label: 'N de Transações'},
        { key: 'alert_type_id', label:'Tipo do Alerta'},
        { key: 'wrong_param', label:'Alerta'},
        { key: 'specific_param', label:'Parametro Especifico'},
        { key: 'default_param', label:'Parametro Geral'},
        { key: 'created_at', label:'Criado Em'},
        { key: 'atypical', label: 'Atipico'}
      ]"
      @row-clicked="getTransaction"
    >
      <template #cell(wrong_param)="data">
        <span v-if="alertsMoneyType.includes(data.item.alert_type_id)">
          {{ data.item.wrong_param | value }}
        </span>

        <span v-else>
          {{ data.item.wrong_param }}
        </span>
      </template>

      <template #cell(specific_param)="data">
        <span v-if="alertsMoneyType.includes(data.item.alert_type_id)">
          {{ data.item.specific_param | value }}
        </span>

        <span v-else>
          {{ data.item.specific_param || '----' }}
        </span>
      </template>

      <template #cell(default_param)="data">
        <span v-if="alertsMoneyType.includes(data.item.alert_type_id)">
          {{ data.item.default_param | value }}
        </span>

        <span v-else>
          {{ data.item.default_param }}
        </span>
      </template>
      <template #thead-top="{ fields }">
        <tr class="alert-header">
          <th
            v-for="field in fields"
            :key="field.key"
          >
            <span v-if="field.key !== 'verify'">{{ field.label }}</span>
            <b-form-checkbox
              v-else
              @change="verifyAll"
            />
          </th>
        </tr>
      </template>
      <template #cell(alert_type_id)="data">
        <span> {{ alertTypeIdToTitle[data.item.alert_type_id] }} </span>
      </template>

      <template
        #cell(verify)="data"
      >
        <div class="d-flex">
          <b-form-checkbox
            v-if="data.item.verified"
            v-model="data.item.verified"
            disabled
            class="d-block m-auto"
          />

          <b-form-checkbox
            v-else
            v-model="data.item.verify"
            class="d-block m-auto"
          />
        </div>
      </template>

      <template
        #cell(atypical)="data"
      >
        <div class="d-flex">
          <b-form-checkbox
            v-model="data.item.atypical"
            class="d-block m-auto"
          />
        </div>
      </template>

      <template #cell(created_at)="data">
        {{ data.item.created_at | date_hour }}
      </template>

      <template #cell(verified)="data">
        {{ data.item.verified ? "Sim" : "Não" }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="filteredItems.length"
      :per-page="8"
      align="center"
      size="sm"
      class="my-2"
    />

    <b-row
      class="mb-2"
    >
      <b-col
        md="3"
      >
        <b-button
          variant="primary"
          @click="update"
        >
          Salvar
        </b-button>
      </b-col>
    </b-row>

    <line-chart
      v-if="filteredItems.length && showChart"
      :key="componentKey"
      class="mt-2"
      :chart-data-test="getChartData(filteredItems)"
      :labels="Object.values(alertTypeIdToTitle)"
    />

    <b-modal

      id="modal"
      ref="modalTransactions"
      hide-footer
      size="xl"
    >
      <div v-if="transactions.length">
        <alert-transaction
          v-if="transactions[0].transactionDetail"
          :items="transactions"
        />

        <ecommerce-table
          v-else
          :items="transactions"
        />
      </div>

    </b-modal>

  </b-card>
</template>

<script>
import {
    BTable, BFormCheckbox, BCard, BModal, BFormGroup, BFormSelect, BRow, BPagination,
    BCol, BFormInput, BButton,
} from 'bootstrap-vue';
import { DateTime } from 'luxon';
import AlertTransaction from './AlertTransaction.vue';
import AlertService from '@/service/transaction_monitoring/alerts';
import { callLoading } from '@/service/utilities';
import LineChart from '@/components/ChartJsBar.vue';

import EcommerceTable from './components/EcommerceTable.vue';

const alertTypeIdToTitle = Object.freeze({
    1: 'Ticket Máximo',
    2: 'Cartão Internacional',
    3: 'Digitada',
    4: 'Ticket Médio',
    5: 'Fallback',
    6: 'Mesmo Cartão',
    7: 'Stripe',
    8: 'Insert',
    9: 'Venda Total',
    10: 'Não Aprovada',
    11: 'Fora de Hora',
    12: 'Não Aprovado Ecommerce',
});

export default {
    components: {
        BTable,
        BFormCheckbox,
        BCard,
        AlertTransaction,
        BModal,
        LineChart,
        BFormGroup,
        BFormSelect,
        BRow,
        BCol,
        BPagination,
        BFormInput,
        EcommerceTable,
        BButton,
    },

    props: {
        items: {
            type: Array,
            default: () => ([]),
        },

        verify: {
            type: String,
            default: 'not-analyzed',
        },

        readOnly: {
            type: Boolean,
            default: () => false,
        },
    },

    data() {
        return {
            alertTypeIdToTitle,
            alertIdSelected: null,
            transactions: [],
            showChart: true,
            componentKey: 0,
            startDate: null,
            endDate: null,
            page: 1,
            tableKey: 1,
            monitoredDetail: [],
            alertsMoneyType: [
                1, 4, 9,
            ],
        };
    },

    computed: {
        filteredItems() {
            let { items } = this;

            const formatDate = date => DateTime.fromISO(date).toFormat('yyyy-MM-dd');

            if (this.verify === 'not-analyzed') {
                items = items.filter(item => !item.verified);
            } else if (this.verify === 'analyzed') {
                items = items.filter(item => item.verified);
            }

            if (this.startDate) {
                items = items.filter(item => {
                    const date = formatDate(item.created_at);
                    return date >= this.startDate;
                });
            }

            if (this.endDate) {
                items = items.filter(item => {
                    const date = formatDate(item.created_at);
                    return date <= this.endDate;
                });
            }

            return items;
        },
    },

    watch: {
        verify() {
            this.refreshComponent();
        },

        startDate() {
            this.refreshComponent();
        },

        endDate() {
            this.refreshComponent();
        },
    },

    methods: {
        async getTransaction({ id }) {
            callLoading(true);
            const { data } = await AlertService.getTransactionsByAlertId(id);
            this.transactions = data.data;
            this.$refs.modalTransactions.show();
            callLoading(false);
        },

        countItems(arr) {
            const countMap = Object.create(null);
            // eslint-disable-next-line no-restricted-syntax
            for (const element of arr) {
                if (!countMap[element.alert_type_id]) {
                    countMap[element.alert_type_id] = 1;
                } else {
                    countMap[element.alert_type_id] += 1;
                }
            }

            return countMap;
        },

        getChartData(arr) {
            const labels = Object.values(alertTypeIdToTitle);
            const countMap = this.countItems(arr);
            const newArray = labels.fill(0);

            // eslint-disable-next-line no-restricted-syntax
            for (const [key, item] of Object.entries(countMap)) {
                newArray[key - 1] = item;
            }

            return newArray;
        },

        async update() {
            const isConfirmed = await this.confirmAnAction('Você deseja realmente atualizar?');
            if (!isConfirmed) return;

            for (const item of this.items) {
                if (!item.verified) {
                    if (item.verify) {
                        item.verified = true;
                    }
                    await AlertService.updateAlert(item);
                }
            }
        },

        verifyAll(value) {
            for (const item of this.items) {
                item.verify = value;
            }
            this.componentKey += 1;
        },

        refreshComponent() {
            this.componentKey += 1;
        },
    },
};
</script>

<style>
.alert-table thead tr {
  display: none
}

.alert-table thead tr.alert-header  {
  display: table-row
}
</style>
