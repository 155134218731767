<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col
          class="mb-1 d-flex"
          md="12"
        >
          <div class="ml-auto d-flex flex-column">
            <b-form-checkbox
              v-model="merchant.is_monitored"
              name="check-button"
              switch
              inline
              class="ml-auto"
              @change="monitoringMerchant"
            >
              Monitorar
            </b-form-checkbox>
            <b-button
              variant="primary"
              class="mt-1"
              @click="monitoredDetail"
            >
              Histórico de Monitoramento
            </b-button>
          </div>
        </b-col>
        <b-col md="1">
          <b-form-group
            label-for="legalName"
            label="Código EC"
          >
            <b-form-input
              id="legalName"
              v-model="merchant.establishment_id"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="1">
          <b-form-group
            label-for="merchantType"
            label="Tipo"
          >
            <b-form-input
              id="merchantType"
              v-model="merchant.type_company"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label-for="taxId"
            label="CPF/ CNPJ"
          >
            <b-form-input
              id="taxId"
              v-model="merchant.tax_id"
              v-mask="[
                '###.###.###-##',
                '##.###.###/####-##',
              ]"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label-for="name"
            label="Nome"
          >
            <b-form-input
              id="name"
              v-model="merchant.name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
            label-for="legalTaxName"
            label="Razão Social"
          >
            <b-form-input
              id="legalTaxName"
              v-model="merchant.legal_tax_name"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label-for="softDescriptor"
            label="Nome na Fatura"
          >
            <b-form-input
              id="softDescriptor"
              v-model="merchant.soft_descriptor"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
            label-for="address"
            label="Endereço"
          >
            <b-form-input
              id="address"
              v-model="merchant.address.address"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label-for="postalCode"
            label="CEP"
          >
            <b-form-input
              id="postalCode"
              v-model="merchant.address.postal_code"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label-for="state"
            label="Estado"
          >
            <b-form-input
              id="state"
              v-model="merchant.address.state"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group
            label-for="city"
            label="Cidade"
          >
            <b-form-input
              id="city"
              v-model="merchant.address.city"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="merchant.comercial_agent"
          md="3"
        >
          <b-form-group
            label-for="comercialAgent"
            label="Representante"
          >
            <b-form-input
              id="city"
              v-model="merchant.comercial_agent.name"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="monitoring-modal"
      no-close-on-backdrop
      hide-footer
      @close="merchant.is_monitored = !merchant.is_monitored"
    >
      <b-form-group
        label="Mensagem"
        label-for="message"
      >
        <b-form-input
          v-if="!merchant.is_monitored"
          v-model="message"
          placeholder="Mensagem"
          name="Mensagem"
        />

        <b-form-select
          v-else
          v-model="message"
          :options="[
            { value: '', text: 'Selecione um Motivo'},
            { value: 'Ressalva/Alerta Compliance', text: 'Ressalva/Alerta Compliance'},
            { value: 'Recadastramento', text: 'Recadastramento'},
            { value: 'Análise de Comportamento', text: 'Análise de Comportamento'},
            { value: 'Suspeita de Fraude', text: 'Suspeita de Fraude'},
            { value: 'Cadastro Substito Pelo Descredenciado Adquirente', text: 'Cadastro Substito Pelo Descredenciado Adquirente'},
          ]"
        />
      </b-form-group>

      <b-button
        variant="primary"
        class="d-block"
        :disabled="!message"
        @click="() => merchant.is_monitored ? monitoring() : desmonitoring()"
      >
        Monitorar
      </b-button>
    </b-modal>

    <b-modal
      id="merchant-monitored-modal"
      size="lg"
      hide-footer
    >
      <monitored-detail :items="monitoredMerchantItems" />
    </b-modal>
  </b-card>
</template>

<script>
import {
    BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BFormCheckbox, BModal, BButton, BFormSelect,
} from 'bootstrap-vue';

import MonitoredDetail from './components/MonitoredDetail.vue';

import MerchantMonitoringService from '@/service/transaction_monitoring/ec';
import { callLoading } from '@/service/utilities';

export default {
    components: {
        BCard, BForm, BFormGroup, BFormInput, BRow, BCol, BFormCheckbox, BModal, BButton, MonitoredDetail, BFormSelect,
    },

    props: {
        merchant: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            message: '',
            monitoredMerchantItems: [],
        };
    },

    methods: {
        monitoringMerchant() {
            this.$bvModal.show('monitoring-modal');
        },

        async monitoring() {
            const confirm = await this.confirmAnAction('Você deseja mesmo monitorar esse EC?');

            if (!confirm) return;

            callLoading(true);
            await MerchantMonitoringService.monitoringMerchant({
                message: this.message,
                establishmentId: this.merchant.establishment_id,
            });
            this.merchant.is_monitored = true;
            this.message = '';
            this.$bvModal.hide('monitoring-modal');
            callLoading(false);
        },

        async desmonitoring() {
            const confirm = await this.confirmAnAction('Você deseja mesmo desmonitorar esse EC?');

            if (!confirm) return;
            callLoading(true);
            await MerchantMonitoringService.desmonitoringMerchant(this.merchant.establishment_id, {
                message: this.message,
                establishmentId: this.merchant.establishment_id,
            });
            this.merchant.is_monitored = false;
            this.message = '';
            this.$bvModal.hide('monitoring-modal');
            callLoading(false);
        },

        async monitoredDetail() {
            const { data } = await MerchantMonitoringService.monitoredDetail(this.merchant.establishment_id);
            this.monitoredMerchantItems = data.data;
            this.$bvModal.show('merchant-monitored-modal');
        },
    },
};
</script>
