<template>
  <div>
    <b-table
      :current-page="page"
      per-page="8"
      :fields="[
        { label: 'id', key: 'id'},
        { label: 'NSU', key: 'nsu'},
        { label: 'Tipo da Venda', key: 'sale_type_id'},
        { label: 'Valor', key: 'value'},
        { label: 'Bandeira', key: 'product_name'},
        { label: 'Data da Transação', key: 'transaction_date'},
        { label: 'N do Cartão', key: 'card_number'},
        { label: 'Parcelas', key: 'installments'},
        { label: 'Método de Entada', key: 'card_entry_mode'},
      ]"
      :items="items"
      class="text-center"
      responsive
    >
      <template #cell(card_entry_mode)="data">
        {{ cardEntryModeId[data.item.transactionDetail.card_entry_modes_id] || '----' }}
      </template>

      <template #cell(value)="data">
        {{ data.item.value | value }}
      </template>

      <template #cell(card_holder_name)="data">
        {{ data.item.transactionDetail }}
      </template>

      <template #cell(product_name)="data">
        <b-img
          width="50"
          class="d-block m-auto"
          :src="getBrandImage(data.item.transactionDetail.brands_id)"
        />
      </template>

      <template #cell(transaction_date)="data">
        {{ formatDate(data.item.transactionDetail.started_at) | date_hour }}
      </template>

      <template #cell(nsu)="data">
        {{ data.item.acquirer_transaction_key }}
      </template>

      <template #cell(card_number)="data">
        {{ data.item.transactionDetail.card_number }}
      </template>

      <template #cell(sale_type_id)="data">
        {{ data.item.sale_type_id === 1 ? 'Débito' : 'Crédito' }}
      </template>
    </b-table>

    <b-pagination
      v-model="page"
      :total-rows="items.length"
      :per-page="8"
      align="center"
      size="sm"
      class="my-0"
    />
  </div>
</template>

<script>
import { BTable, BImg, BPagination } from 'bootstrap-vue'
import { DateTime } from 'luxon'

export default {
  components: {
    BTable,
    BImg,
    BPagination,
  },

  props: {
    items: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      page: 1,
      cardEntryModeId: {
        1: 'digitado',
        2: 'tarja magnética',
        3: 'chip com contato',
        4: 'chip sem contato',
        5: 'fallback de chip de tarja',
        6: 'chip sem contato simulado tarja',
        7: 'chip sem contato EMV',
        8: 'transação necessita de fallback',
        9: 'fallback de tarja para digitado',
      },
    }
  },

  methods: {
    getBrandImage(card) {
      const paymentSchemesToImg = {
        5: 'amex.svg',
        2: 'mastercard.svg',
        1: 'visa.svg',
        6: 'elo.svg',
        4: 'hipercard.svg',
      }
      const src = paymentSchemesToImg[card] || 'default.svg'
      // eslint-disable-next-line import/no-dynamic-require, global-require
      const img = require(`@/assets/images/brands/${src}`)

      return img
    },

    formatDate(date) {
      const dateTime = DateTime.fromISO(date)

      const dateTimeZone = dateTime.plus({ hours: 3 })
      return dateTimeZone
    },
  },
}

</script>
