import methods from '@/enums/methods';

import TransactionMonitoringService from './base';

class AlertService extends TransactionMonitoringService {
    getUnverifiedAlerts() {
        return this.fetch({
            url: `${this.baseUrl}/merchants/alerts`,
        });
    }

    getAlerts({
        ec,
        startDate,
        endDate,
        verified,
    }) {
        return this.fetch({
            url: `${this.baseUrl}/alerts`,
            params: {
                ec,
                start_date: startDate,
                end_date: endDate,
                verified,
            },
        });
    }

    getAlertType() {
        return this.fetch({
            url: `${this.baseUrl}/alert-types`,
        });
    }

    getMonitored() {
        return this.fetch({
            url: `${this.baseUrl}/merchant`,
        });
    }

    getAlertByMerchantCode(ec) {
        return this.fetch({
            url: `${this.baseUrl}/merchant/${ec}/alert`,
        });
    }

    getTransactionsByAlertId(alertId) {
        return this.fetch({
            url: `${this.baseUrl}/alert/${alertId}/transaction`,
        });
    }

    updateAlert(alert) {
        return this.fetch({
            method: methods.PUT,
            url: `${this.baseUrl}/alert/${alert.id}`,
            data: alert,
        });
    }
}

export default new AlertService();
