<script>
import { Bar } from 'vue-chartjs';

export default {
    extends: Bar,
    props: {
        chartDataTest: {
            default: () => ([]),
            type: Array,
        },

        axis: {
            type: String,
            default: () => 'x',
        },

        labels: {
            default: () => ([]),
            type: Array,
        },
    },
    data() {
        return {
            chartData: {
                labels: this.labels,
                datasets: [
                    {
                        label: 'Line Chart',
                        axis: this.axis,
                        data: this.chartDataTest,
                        fill: false,
                        borderColor: '#2E2C6C',
                        backgroundColor: '#2E2C6C',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                scales: {
                    yAxes: [{
                        display: true,
                        ticks: {
                            min: 0,
                            max: Math.max.apply(null, this.chartDataTest) > 6 ? Math.max.apply(null, this.chartDataTest) : 6,
                            beginAtZero: true,
                        },
                    }],
                    xAxes: [{
                    }],
                },
                legend: {
                    display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        };
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    },
};
</script>
